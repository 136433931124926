import { REACT_APP_API_BASE, REACT_APP_CAPTCHA_TOGGLE } from "./terraformConfig";

async function fetchPaymentList(body) {
  const requestData = {
    "ReferenceID": body.ReferenceID,
    "PrincipalName": body.PrincipalName,
    "PostalZipCode": body.PostalZipCode,
  };
  //use waf integration if react-app-captcha-toggle is true else use regular fetch
  const fetchFunction = REACT_APP_CAPTCHA_TOGGLE ? window.AwsWafIntegration.fetch : fetch;
  try {
    const response = await fetchFunction(`${REACT_APP_API_BASE}/api/ca-bond/v1/payment/experience`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    });
    if (response.status === 405) {
      return {
        statusCode: 405,
        body: '[]',
        headers: response.headers,
      };
    };
    if (!response.ok) {
      throw Error('Failed to fetch data');
    }

    return response.json();

  } catch (error) {
    console.log(error.message);
  }

}

export { fetchPaymentList }
