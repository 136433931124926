import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { TdsLoadingSpinner } from '@trv-tds/react'
import PaymentForm from './components/PaymentForm'

const App = lazy(() => import('./App'))
//const PaymentForm = lazy(() => import('./components/PaymentForm'))
const PaymentList = lazy(() => import('./components/PaymentList'))
const PaymentConfirmation = lazy(() => import('./components/PaymentConfirmation'))
const NotFound = lazy(() => import('./components/NotFound'))

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<TdsLoadingSpinner size="xxx-large" label-displayed overlay centered />}>
        <Routes>
          <Route path="/index.html" element={<Navigate to="/" />} />
          <Route path="/" element={<App />}>
            <Route index element={<PaymentForm />} />
            <Route path="payment-list" element={<PaymentList />} />
            <Route path="confirmation" element={<PaymentConfirmation />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
