import React from 'react';
import { TdsAlert } from '@trv-tds/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function FormAlert({ errorCount }) {
    const { t } = useTranslation();
    const scrollToErrorSection = (e) => {
        e.preventDefault();
        const firstErrorField = document.querySelector("tds-field[alert='error']");
        if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth' });
            const inputError = document.querySelector("tds-field[alert='error'] > input");
            inputError.focus();
        }
    };

    const errorlinkText = errorCount === 1 ? t('form_errors.go_to_error') : t('form_errors.go_first_error');

    return (
        <div className="medium-container">
            {errorCount > 0 && (
                <TdsAlert type="error" dismissible role='alert' aria-live="assertive">
                    <p>
                        {errorCount === 1 ? (
                            <>
                                {t('form_errors.there_is_message')} <span id="error-count-output">{errorCount}</span>  {t('form_errors.singular_error')}.
                            </>
                        ) : (
                            <>
                                {t('form_errors.there_are_message')} <span id="error-count-output">{errorCount}</span>  {t('form_errors.plural_error')}.
                            </>
                        )}
                        &nbsp;
                        &nbsp;
                        <Link
                            onClick={scrollToErrorSection}
                            id="go-to-error-link"
                            className="tds-ml-xs tds-link-no-underline"
                            to=""
                        >
                            {errorlinkText}
                        </Link>
                        .
                    </p>
                </TdsAlert>
            )}
        </div>
    );
}

export default FormAlert;