import React, { useState, useEffect } from 'react';
import { TdsField, TdsButton, TdsAlert, TdsLoadingSpinner } from '@trv-tds/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchPaymentList } from '../services/PaymentFormService';
import { removeFromLocalStorage, setItemFromLocalStorage } from '../services/localStorageService';
import PaymentModal from './PaymentModal'
import FormAlert from './FormAlert'
import { TdsModal } from '@trv-tds/react';
import { getQtyPayments } from '../utilities/helper';
import { REACT_APP_HOSTED_PAYMENT_FORM_URL, REACT_APP_CAPTCHA_API_KEY, REACT_APP_CAPTCHA_SCRIPT, REACT_APP_CAPTCHA_TOGGLE } from '../services/terraformConfig';
function PaymentForm() {
  const [isDisabled, setDisabled] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    referenceNumber: '',
    principalName: '',
    billingZip: '',
    emailAddress: '',
  });
  const [modalValues, setModalValues] = useState({
    amount: '',
    trvGuid: '',
    slGuId: '',
    invoiceType: '',
    descriptionEn: '',
    descriptionFr: '',
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [errorCount, setErrorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    removeFromLocalStorage('payment-form');
    removeFromLocalStorage('payment-options');
    removeFromLocalStorage('qty-payments');
    removeFromLocalStorage('confirmation');
    const params = new URLSearchParams(window.location.search);
    if (params) {
      setFormValues({
        emailAddress: params.get('emailAddress') || '',
        referenceNumber: params.get('referenceNumber') || '',
        principalName: params.get('principalName') || '',
        billingZip: params.get('billingZip') || '',
      })
    }
    const loadCaptchaScript = () => {
      const existingScript = document.getElementById('awswaf-captcha');

      if (!existingScript) {
        const captchaScript = document.createElement('script');
        const captchaScriptUrl = REACT_APP_CAPTCHA_SCRIPT;
        captchaScript.src = captchaScriptUrl;
        captchaScript.id = 'awswaf-captcha';
        captchaScript.type = 'text/javascript';
        captchaScript.async = false;
        captchaScript.onload = () => {
          console.log("captcha script loaded")
        }
        document.getElementsByTagName('head')[0].appendChild(captchaScript);
      }
    }
    const loadHpfForm = () => {
      const existingScript = document.getElementById('hpf');

      if (!existingScript) {
        const jpmcPaymentScript = document.createElement('script');
        const hostedPaymentFormUrl = REACT_APP_HOSTED_PAYMENT_FORM_URL;
        jpmcPaymentScript.src = hostedPaymentFormUrl;
        jpmcPaymentScript.id = 'hpf';
        jpmcPaymentScript.type = 'text/javascript';
        jpmcPaymentScript.async = false;
        jpmcPaymentScript.onload = () => {
          console.log('payment script loaded');
        };
        document.getElementsByTagName('head')[0].appendChild(jpmcPaymentScript);
      }
    };
    if (REACT_APP_CAPTCHA_TOGGLE) {
      loadCaptchaScript();
    }
    loadHpfForm();
  }, [])

  const handlePaymentCloseConfirmation = () => {
    setShowModal(false);
  };

  const handleCompletePayment = (paymentResult) => {
    removeFromLocalStorage('payment-options');
    setShowModal(false);
    navigate(`/confirmation`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const errorMessages = {
    emailAddress: t('form_errors.valid_email_address'),
    referenceNumber: t('form_errors.bond_number_required'),
    principalName: t('form_errors.principal_name_required'),
    billingZip: t('form_errors.valid_postal_code')
  };
  const validators = {
    emailAddress: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim()) && value.trim() !== '',
    referenceNumber: (value) => /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/.test(value.trim()) && value.trim() !== '',
    //eslint-disable-next-line
    principalName: (value) => /^[\p{L}\p{N}'\-\.\(\)&@]+(?: [\p{L}\p{N}'\-\.\(\)&@]+)*\.?$/u.test(value.trim()) && value.trim() !== '',
    billingZip: (value) => /\b\d{5}(-\d{4})?\b|\b[A-Z]\d[A-Z] ?\d[A-Z]\d\b/i.test(value.trim()) && value.trim() !== ''
  };

  const validateField = (fieldName, value) => {
    const newErrors = { ...errors };
    if (!validators[fieldName](value)) {
      newErrors[fieldName] = errorMessages[fieldName];
    } else {
      delete newErrors[fieldName];
    }
    setErrors(newErrors);
    setErrorCount(Object.keys(newErrors).length);
    return Object.keys(newErrors).length === 0;
  };


  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    Object.entries(formValues).forEach(([fieldName, fieldValue]) => {
      if (!validators[fieldName](fieldValue)) {
        newErrors[fieldName] = errorMessages[fieldName];
        valid = false;
      }
    });
    setErrors(newErrors);
    setErrorCount(Object.keys(newErrors).length);
    return valid;
  };

  const openCaptchaModal = () => {
    setShowCaptchaModal(true);
  };

  const closeCaptchaModal = () => {
    setShowCaptchaModal(false);
  };

  const handleSubmit = async (event) => {

    setDisabled(true);
    if (event) {
      event.preventDefault();
    }

    setError(null);
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      const body = {
        EmailAddress: formValues.emailAddress.trim(),
        ReferenceID: formValues.referenceNumber.trim().toUpperCase(),
        PrincipalName: formValues.principalName.trim(),
        PostalZipCode: formValues.billingZip.trim(),
      };
      try {
        const data = await fetchPaymentList(body);
        //implement  api aws waf captcha
        if (data.statusCode === 405) {
          setLoading(false);
          const captchaContainer = document.querySelector("#captcha-container");
          // eslint-disable-next-line no-undef
          AwsWafCaptcha.renderCaptcha(captchaContainer, {
            // eslint-disable-next-line no-undef
            //this api key will be requested from api gateway endpoint
            apiKey: REACT_APP_CAPTCHA_API_KEY,
            onSuccess: (wafToken) => {
              handleSubmit(null);
            },
            dynamicWidth: true
          });

          openCaptchaModal();
        }

        else {

          const dataParsed = JSON.parse(data.body);
          setItemFromLocalStorage('payment-form', JSON.stringify(body));
          setItemFromLocalStorage('qty-payments', getQtyPayments(dataParsed));
          if (dataParsed.length === 1) {
            setLoading(false);
            setModalValues(prevState => ({
              ...prevState,
              amount: dataParsed[0]['Amount'],
              invoiceType: dataParsed[0]['InvoiceType'],
              descriptionEn: dataParsed[0]['DescriptionEn'],
              descriptionFr: dataParsed[0]['DescriptionFr'],
              trvGuid: dataParsed[0]['TrvGuid'],
              slGuId: dataParsed[0]['SlGuid']
            }));
            setShowModal(true);
          } else if (dataParsed.length > 1) {
            setItemFromLocalStorage('payment-options', JSON.stringify(data.body));
            navigate(`/payment-list`);
          }
          else {
            setError(t('form_errors.payment_not_found'));
            setLoading(false);
          }
        }


      } catch (error) {
        setError(t('form_errors.fetching_error'));
        setLoading(false);
      }
    }
    window.scrollTo(0, 0);
    setLoading(false);
    setDisabled(false);
  };

  return (
    <div className="tds-container payment-form">
      {showModal &&
        <PaymentModal
          closeListener={handlePaymentCloseConfirmation}
          completePayment={handleCompletePayment}
          amount={modalValues.amount}
          referenceNumber={formValues.referenceNumber.toUpperCase()}
          emailTo={formValues.emailAddress}
          invoiceType={modalValues.invoiceType}
          slGuid={modalValues.slGuId}
          trvGuid={modalValues.trvGuid}
        />
      }
      {error && <TdsAlert type="error" role='alert' className='alert-container' style={{ textAlign: 'left' }} dismissible aria-live="assertive">{error}</TdsAlert>}
      {loading && (
        <>
          <TdsLoadingSpinner size="xxx-large" overlay centered aria-live='polite' />
          <span role='alert' aria-live='assertive' className='only-read'><span>{t('page_loading')}</span></span>
        </>
      )}
      <TdsModal id="my-modal" opened={showCaptchaModal} dialog-title="" size="small" onTdsClosed={closeCaptchaModal} aria-modal='true'>
        <div id='captcha-container'></div>
      </TdsModal>
      <FormAlert errorCount={errorCount} />
      <h1 className='tds-h1--small tds-h1--semibold'>{t('title_form')}</h1>
      { /* eslint-disable-next-line */}
      <form onSubmit={handleSubmit} data-testid="form" className="medium-container" role='form'>
        <TdsField id="email" className='payment-input' alert={errors.emailAddress ? 'error' : ''} message={errors.emailAddress}>
          <label for="email_address">{t('email_address_label')}</label>
          <input id="email_address" type="text" name="emailAddress" value={formValues.emailAddress} onChange={handleChange} onBlur={handleBlur} maxLength={100} />
        </TdsField>
        {' '}
        <TdsField id="referenceNumber" className='payment-input' alert={errors.referenceNumber ? 'error' : ''} message={errors.referenceNumber} helpText={t('help_bond_label')}>
          <label for="reference_number" slot='label'>{t('bond_label')}</label>
          <input id="reference_number" type="text" name="referenceNumber" value={formValues.referenceNumber} onChange={handleChange} onBlur={handleBlur} maxLength={100} />
        </TdsField>
        {' '}
        <TdsField id="principalName" className='payment-input' alert={errors.principalName ? 'error' : ''} message={errors.principalName} helpText={t('help_last_name_label')}>
          <label for="principal_name" slot='label'>{t('last_name_label')}</label>
          <input id="principal_name" type="text" name="principalName" value={formValues.principalName} onChange={handleChange} onBlur={handleBlur} maxLength={100} />
        </TdsField>
        {' '}
        <TdsField id="billingZip" className='payment-input' alert={errors.billingZip ? 'error' : ''} message={errors.billingZip}>
          <label for="billing_zip" slot='label'>{t('billing_zip_label')}</label>
          <input id="billing_zip" type="text" name="billingZip" value={formValues.billingZip} onChange={handleChange} onBlur={handleBlur} style={{ width: '28%' }} />
        </TdsField>
        {' '}
        <TdsField>
          <TdsButton buttonType="submit" variant="primary" disabled={isDisabled}>
            {t('submit_button')}
          </TdsButton>
        </TdsField>
      </form>
      <p className='tds-small'>{t('support_message')}
        <br />
        <br />
        <a className='no-under-line' href={`mailto:${t('support_email')}`}>{t('support_email')}</a>
      </p>
    </div>
  );
}

export default PaymentForm;