import axios from 'axios';
import { REACT_APP_API_BASE } from '../services/terraformConfig';
require('es6-promise/auto');

const client = axios.create({
  baseURL: window._env_ && window._env_.API_BASE ? window._env_.API_BASE : REACT_APP_API_BASE,
  responseType: 'json'
});


const logError = async error => {
  const errorToLog = JSON.stringify(error);
  console.log(`API error: ${errorToLog}`);
};

export const handleError = error => {
  if (!error) return;
  logError(error);
  return Promise.reject(error);
};

export default client;
