const ttl = 600000;

function setItemFromLocalStorage(key, value) {
    const now = new Date();
    const item = {
        value: JSON.parse(value),
        expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item));
}

function loadFromLocalStorage(key) {
    const itemStr = localStorage.getItem(key);
    if(!itemStr) return null
    const item = JSON.parse(itemStr); 
    const now = new Date();

    if(now.getTime() > item.expiry){
        removeFromLocalStorage(key);
        return null;
    }

    return item.value;
}

function removeFromLocalStorage(key) {
    localStorage.removeItem(key);
}

export { setItemFromLocalStorage, loadFromLocalStorage, removeFromLocalStorage }
