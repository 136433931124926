import React from 'react';
import PropTypes from 'prop-types';
import { TdsAlert } from '@trv-tds/react';
const AlertNotices = ({ alertMessages }) => {
  return (
    <div>
      {alertMessages?.map((item, index) => (
        <TdsAlert key={index} type="error"> 
        <h6>{item.toUpperCase()}</h6>
        </TdsAlert>
      ))}
    </div>
  );
};
AlertNotices.propTypes = {
  alertMessages: PropTypes.array
};
export default AlertNotices;