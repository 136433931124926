import React, { useState, useEffect, useRef } from 'react';
import { TdsModal, TdsLoadingSpinner, TdsAlert, TdsModalButtons, TdsButton } from '@trv-tds/react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setItemFromLocalStorage } from '../services/localStorageService';
import { formatCurrencyInt, formatUTCDate } from '../utilities/helper';
import api from '../utilities/api';
import AlertNotices from './AlertNotices';
import i18next from 'i18next';

const PaymentModal = (props) => {
  const [iframeErrored, setIframeErrored] = useState(false);
  const [isIframeVisible, setIsIframeVisible] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [networkErrorMessage, setNetworkErrorMessage] = useState(null);
  const [iframeReady, setIframeReady] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [error2290MessagesList, setError2290MessagesList] = useState([]);
  const [showErrorNotice, setShowErrorNotice] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showInfoNotice, setShowInfoNotice] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const paymentModalRef = useRef(null);
  const { t } = useTranslation();
  const currentLanguage = i18next.language;

  const handleReload = () => {
    setIframeErrored(false);
    setNetworkError(false);
    setNetworkErrorMessage(null);
    fetchPaymentIframeUrl();
  };

  const handleXButtonListener = () => {
    props.closeListener('close');
  };

  const fetchPaymentAdditionalInfo = async (paymentRequest) => {
    try {
      const addInfoPayload = {
        referenceId: props.referenceNumber,
        emailTo: props.emailTo,
        invoiceType: props.invoiceType,
        lang: currentLanguage,
        amount: formatCurrencyInt(props.amount, currentLanguage),
        paymentDate: paymentRequest.paymentDate,
        ccsetup: 'N',
        lob: 'SI',
        policyno: `${props.referenceNumber}`,
        source: 'SL',
        trvGuid: props.trvGuid,
        slGuid: props.slGuid,
        uid: paymentRequest.uID,
      };

      const paymentConfig = {
        headers: {
          'payment-token': await getPaymentToken()
        }
      };

      const response = await api.post('/api/ca-bond/v1/payment/experience', addInfoPayload, paymentConfig);

      return response.data;
    } catch ({ response }) {
      setIframeReady(true);
      setIframeErrored(false);
      // const paymentfailureObject = {
      //   amount: props.amount,
      //   uid: paymentRequest.uID,
      //   builderNumber: props.builderNumber,
      //   applicationNumber: props.applicationNumber,
      //   scheduleType: props.scheduleType,
      //   builderName: props.builderName,
      //   reason: 'paymentfailure',
      // };
      //  await sendEmail(paymentfailureObject);
      return null;
    }
  };

  const fetchPaymentIframeUrl = async () => {
    let iFramePayload = {
      amount: formatCurrencyInt(props.amount, currentLanguage),
      ccsetup: 'N',
      lob: 'SI',
      policyno: `${props.referenceNumber}`,
      source: 'SL',
      uid: ''
    };

    if (currentLanguage === 'fr') iFramePayload.lang = 'fr_CA';

    const paymentConfig = {
      headers: {
        'payment-token': await getPaymentToken()
      }
    };

    try {
      const response = await api.post('/api/ca-bond/v1/payment/experience', iFramePayload, paymentConfig);
      setTimeout(() => {
        if (networkError) {
          setIframeUrl('');
        } else {
          const iframeUrl = JSON.parse(response.data.body);
          setIframeUrl(iframeUrl.body.replaceAll('"', ""));
        }
      }, 4000);
    } catch ({ response }) {
      setIframeErrored(true);
      // helper.handleError(response);
    }
  };

  const getPaymentToken = async () => {
    try {
      const storedToken = sessionStorage.getItem('paymentToken');
      const parsedToken = JSON.parse(storedToken);

      if (storedToken && !hasPaymentTokenExpired(parsedToken)) {
        return parsedToken.access_token;
      } else {
        sessionStorage.removeItem('paymentToken');
        const response = await api.get('/api/ca-bond/v1/payment/experience');
        const bodyResponse = JSON.parse(response.data.body);
        const tokenResponse = JSON.parse(bodyResponse.body);

        const now = new Date();
        const tokenExpiryTime = now.getTime() + tokenResponse.expires_in;

        sessionStorage.setItem(
          'paymentToken',
          JSON.stringify({
            ...tokenResponse,
            expiresIn: tokenExpiryTime
          })
        );

        return tokenResponse.access_token;
      }
    } catch ({ error }) {
      sessionStorage.removeItem('paymentToken');
      setIframeErrored(true);
    }
  };

  const hasPaymentTokenExpired = paymentToken => {
    if (!paymentToken) {
      return true;
    }

    const expiresIn = paymentToken.expiresIn;
    const now = new Date();

    const timeToExpiry = expiresIn - now.getTime();

    return timeToExpiry <= 0;
  };

  const setErrorNotice = (errorMessages) => {
    setShowErrorNotice(true);
    setErrorMessages(errorMessages);
  };

  const setInfoMessageState = (infoMessage) => {
    setShowInfoNotice(true);
    setInfoMessage(infoMessage);
  };

  const handleConnectionChange = (event) => {
    if (event.type === 'offline') {
      setNetworkError(true);
      setIframeUrl('');
      setNetworkErrorMessage(t(`errors.01`));
    }
    if (event.type === 'online') {
      setNetworkError(true);
      setNetworkErrorMessage(t(`errors.01`));
    }
  };

  useEffect(() => {
    const handleHpfReady = () => {
      setIframeReady(true);
      setIframeErrored(false);
    };

    const handleTransactionErrors = (event) => {
      let errorCodeList = [];
      let error2290MessagesList = [];

      event
        .filter((err) => err.status_code !== 2290)
        .forEach((err) => errorCodeList.push(err.status_code));
      event
        .filter((err) => err.status_code === 2290)
        .forEach((err) => error2290MessagesList.push(err.status_message.replace("CODE DE S&Eacute;CURIT&Eacute;", "CODE DE SÉCURITÉ")));

      setError2290MessagesList(error2290MessagesList);
      handleIFramePaymentErrors(errorCodeList);
    };

    const handleWhatsThis = (event) => {
      const message = transformInfoMessage(event);
      handleInfoMessages(message);
    };

    const handleStartPayment = () => {
      paymentModalRef.current.scrollTo(0, 0);
      setShowErrorNotice(false);
      setShowInfoNotice(false);
    };

    const handleCompletePayment = async (paymentResult) => {
      setIsIframeVisible(false);
      setPaymentInProgress(true);
      const currentDate = new Date().toISOString();
      let paymentRequest = {
        uID: paymentResult.uid || paymentResult.uID,
        applicationNumber: props.applicationNumber,
        paymentDate: currentLanguage === 'en' ? formatUTCDate(currentDate, 'MMM DD, YYYY h:mm:ss a', 'en') : formatUTCDate(currentDate, 'MMM DD, YYYY h:mm:ss a', 'fr'),
        paymentDateFormatted: formatUTCDate(currentDate, 'D MMM YYYY HH [h] mm [min] ss [s]', 'fr').replace('.', ''),
      };

      paymentResult.uID = paymentResult.uid;

      try {
        const additionalInfo = await fetchPaymentAdditionalInfo(paymentRequest);
        const additionalInfoBody = JSON.parse(additionalInfo.body);
        const additionalInfoParsed = JSON.parse(additionalInfoBody.body);
        if (additionalInfoParsed) {
          paymentResult.invoiceType = props.invoiceType;
          paymentResult.cardBrand = additionalInfoParsed.card_brand;
          paymentResult.orderId = additionalInfoParsed.order_id;
          paymentResult.paymentDate = paymentRequest.paymentDate;
          paymentResult.paymentDateFormatted = paymentRequest.paymentDateFormatted;
          paymentResult.mPAN = additionalInfoParsed.mpan;
          paymentResult.totalAmount = additionalInfoParsed.total_amount;
        }
      }
      catch (error) {
        paymentResult.paymentError = true;
        paymentRequest.invoiceType = props.invoiceType;
        paymentRequest.totalAmount = props.amount;
      }
      finally {
        setPaymentInProgress(false);
        if (paymentResult.paymentError) {
          setItemFromLocalStorage('confirmation', JSON.stringify(paymentRequest))
        }
        else {
          setItemFromLocalStorage('confirmation', JSON.stringify(paymentResult))
        }
        props.completePayment(paymentResult);
      }
    };

    window.hpfReady = handleHpfReady;
    window.handleTransactionErrors = handleTransactionErrors;
    window.whatsThis = handleWhatsThis;
    window.startPayment = handleStartPayment;
    window.completePayment = handleCompletePayment;
    window.cancelPayment = handleXButtonListener;
    window.ononline = handleConnectionChange;
    window.onoffline = handleConnectionChange;

    fetchPaymentIframeUrl();

    return () => {
      window.hpfReady = undefined;
      window.handleTransactionErrors = undefined;
      window.whatsThis = undefined;
      window.creHandleErrors = undefined;
      window.completePayment = undefined;
      window.cancelPayment = undefined;
      window.completeCREPayment = undefined;
      window.ononline = undefined;
      window.onoffline = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapError = (code) => {
    const errorMessage = t(`errors.${code}`);
    return errorMessage !== `errors.${code}` ? errorMessage : t(`errors.00`);
  };

  const mapErrorMessage = (errorCodeList) => {
    const errorMessage = errorCodeList.map((errorCode) => mapError(errorCode));
    const errorMessages = [];
    errorMessages.push(errorMessage);
    return errorMessages;
  };

  const handleIFramePaymentErrors = (errorCodes) => {
    const errorMessages = mapErrorMessage(errorCodes);
    setPaymentInProgress(false);
    setErrorNotice(errorMessages[0]);
  };

  const handleInfoMessages = (infoMessage) => {
    setInfoMessageState(infoMessage);
  };

  const transformInfoMessage = (data) => {
    if (data === 'cvv') {
      return 'The 3 numbers on the back of your card.';
    }
  };

  return iframeErrored ? (
    <TdsModal opened='true' size='small' aria-modal='true'>
      <p>{t('iframe_error')}</p>
      <TdsModalButtons slot="footer">
        <TdsButton variant="primary" data-dismiss="modal" onClick={handleReload}>{t('try_again_button')}</TdsButton>
        <TdsButton data-dismiss="modal">{t('cancel_button')}</TdsButton>
      </TdsModalButtons>
    </TdsModal>
  ) : (
    <TdsModal size='small' opened='true' onTdsClosed={handleXButtonListener} noscroll={!iframeReady || paymentInProgress} noBodyPadding={true} ref={paymentModalRef} aria-modal='true'>
      <div slot='title' id="paymentmodal-title">{t('title_iframe')}</div>
      <div style={{ position: 'relative' }}>
        <div>
          {!iframeReady && (
            <div className='custom-overlay'>
              <div style={{ marginLeft: 20, marginRight: 20 }} role="alert">
                <TdsLoadingSpinner id="loading-iframe" centered="true" label={t('provider_redirect')} labelDisplayed={true} aria-live="polite" />
              </div>
            </div>
          )}
          {paymentInProgress && (
            <div className='custom-overlay'>
              <div style={{ marginLeft: 20, marginRight: 20 }} role="alert">
                <TdsLoadingSpinner id="loading-iframe" centered="true" label={t('processing_payment')} labelDisplayed={true} aria-live="polite" />
              </div>
            </div>
          )}
          <div style={{ textAlign: 'left' }}>
            {showErrorNotice ? <AlertNotices alertMessages={error2290MessagesList} /> : null}
            {showErrorNotice ? <AlertNotices alertMessages={errorMessages} /> : null}
            {showInfoNotice ? <TdsAlert type="info"><h6>{infoMessage.toUpperCase()}</h6></TdsAlert> : null}
            {networkError && <TdsAlert type="error"><h6>{networkErrorMessage}</h6></TdsAlert>}
          </div>
        </div>
        <div style={{ paddingTop: '1.25rem' }}>
          {isIframeVisible ? (
            <iframe width="340px" frameBorder="0" height="650px" id="iframe" title={t('title_iframe_attr')} src={iframeUrl}></iframe>

          ) : (
            <iframe width="340px" frameBorder="0" height="650px" id="iframe" title={t('title_iframe_attr')} src=''></iframe>
          )}
        </div>
      </div>
    </TdsModal>
  );
};

PaymentModal.propTypes = {
  closeListener: PropTypes.func.isRequired,
  completePayment: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  referenceNumber: PropTypes.string.isRequired,
  emailTo: PropTypes.string.isRequired,
  invoiceType: PropTypes.string.isRequired,
  slGuid: PropTypes.string.isRequired,
  trvGuid: PropTypes.string.isRequired,
};

export default PaymentModal;
