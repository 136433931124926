import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-ca';

export function formatCurrencyInt(amt, lng) {
  if (lng === 'en') {
      amt = amt.replace(/,/g, '');
  } else {
    amt = amt.replace(/\s/g, '');
    // If there is a comma and a period, it is a thousand separator and a decimal point
    if (amt.includes(',') && amt.includes('.')) {
        // Treat comma as the decimal separator
        amt = amt.replace(/\./g, '').replace(',', '.');
    } else if (amt.includes(',')) {
        // Remove all periods (used as thousands separators) and replace the last comma with a period
        amt = amt.replace(/\./g, '');
        amt = amt.substring(0, amt.lastIndexOf(',')) + '.' + amt.substring(amt.lastIndexOf(',') + 1);
    }
}
  amt = parseFloat(amt);
  const formatter = new Intl.NumberFormat(lng === 'en' ? 'en-CA' : 'fr-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });
  return formatter.format(amt);
}

export function formatDate() {
  const now = new Date();
  const year = String(now.getFullYear()).slice(-2);
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return year + month + day + hours + minutes + seconds;
 }

export function formatUTCDate(dateString, format = 'MMM DD, YYYY', lng, message = '') {
  lng === 'fr' ? moment.locale('fr') : moment.locale('en-ca');
  return !dateString ? message : moment.utc(dateString, 'YYYYMMDDHHmmss').local().format(format);
};

export function getQtyPayments(paymentList) {
  return paymentList.length;
};